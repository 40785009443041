<template>
  <b-modal
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="kartaPripadu-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Karta případu č. {{ kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100 m-height" @click.stop>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Základní info" active title-item-class="pb-1 karta-tab">
              <b-card-text class="tab-content-karta">
                <div class="bold">
                  {{ getFirstLine() }}
                </div>
                <div class="line-info">
                  <span class="title-grey">Adresa instalace:</span> {{ getAddresaInstalace()}}
                </div>
                <div class="line-info">
                  <span class="title-grey">Katastrální území:</span>
                  {{ getKatastralniUzemi() }}
                  <span class="title-grey pl-2">Stojí na pozemku p.č.</span>
                  {{ getStojiNaPozemku() }}
                </div>
                <div class="line-info">
                  <span class="title-grey">Způsoby ochrany nemovitosti:</span>
                  {{ getZpusobyOchranyNemovitosti }}
                </div>

                <div v-for="(item, indx) in info.personasInfo" :key="indx + 'personasInfo'">
                  <div class="line-info pt-3">
                    <b>Osoba č. {{ indx + 1 }}</b><br>
                    <b>{{ getJoinedName(item) }}</b>, telefonní číslo: {{ item.phone }}
                  </div>
                  <div class="line-info">
                    <span class="title-grey">Emailová adresa:</span>
                    <a :href="getMailTo(item.email)" title="Poslat email">
                      {{ item.email }}
                    </a>
                  </div>
                </div>

                <div v-if="isVyhra" class="line-info pt-3">
                  <b>Technologie FVE:</b><br>
                  panely - <b>{{info.numberOfPanels}}</b>x <b>{{info.panelType}}</b><br>
                  počet stringů - <b>{{info.numberOfThongs}}</b><span v-html="getPocetOptimizeru"></span>
                  typ střídače - <b>{{info.inverterType}}</b><br>
                  <span v-html="getTypBaterie"></span>
                  příslušenství - <b>{{info.accessories}}</b><span v-html="getNabiecka"></span>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab title="Historie" title-item-class="pb-1 karta-tab">
              <b-card-text class="tab-content-karta">
                <div v-for="(history, indx) in zakazkaHistory" :key="indx + 'zakazkaHistory'"
                     v-html="history"
                     class="zakazkaHistory"
                />
                <div v-if="!zakazkaHistory.length" class="pt-5">
                  <h6 class="text-nowrap w-100 text-center fs-20px pt-5">Žádné záznamy</h6>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab title="Popis případu" title-item-class="pb-1 karta-tab">
              <b-card-text class="tab-content-karta">
                <div
                    v-if="popisPripadu"
                    v-html="popisPripadu"
                    class="zakazkaHistory"
                />
                <div v-else class="pt-5">
                  <h6 class="text-nowrap w-100 text-center fs-20px pt-5">Žádné záznamy</h6>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab title="Popis servisu" title-item-class="pb-1 karta-tab">
              <b-card-text class="tab-content-karta">
                <div
                    v-if="popisServisu"
                    v-html="popisServisu"
                    class="zakazkaHistory"
                />
                <div v-else class="pt-5">
                  <h6 class="text-nowrap w-100 text-center fs-20px pt-5">Žádné záznamy</h6>
                </div>
              </b-card-text>
            </b-tab>

            <b-tab title="Generátor dokumentu" title-item-class="pb-1 karta-tab">
              <b-card-text class="tab-content-karta">
                <div class="line-info">
                  Obhlídkový formulář (částečně vyplněný) - <a @click="generateXlsxOF" href="#">{{ getOfFileName }}</a>
                </div>
                <div class="line-info" v-for="(persona, indx) in info.personasInfo" :key="persona._id">
                  GDPR (nepodepsáno), osoba č. {{ indx + 1 }} - <a @click="generateGDPR(persona, indx)" href="#">{{ getGdprFileName(persona, indx) }}</a>
                </div>
                <div class="line-info">
                  Protokol o nastavení ochran {{ getPonoInfo }} -
                  <a v-if="getPowerPlantCapacity && getDistributor" @click="generatePONO" href="#">{{ getPonoFileName }}</a>
                  <span v-else
                        class="color-grey"
                        v-b-tooltip.hover="{
                          title: 'Žádná data pro protokol o nastavení ochran',
                          delay: { show: 200, hide: 200 },
                          variant: 'secondary'
                        }"
                  >
                    {{ getPonoFileName }}
                  </span>
                </div>

              </b-card-text>
            </b-tab>

            <b-tab title="Podepsané dokumenty" title-item-class="pb-1 karta-tab">
              <b-card-text class="tab-content-karta">
                <div class="pt-5">
                  <h6 class="text-nowrap w-100 text-center fs-20px pt-5">Žádné záznamy</h6>
                </div>
              </b-card-text>
            </b-tab>

          </b-tabs>
        </b-card>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import commonMixin from "@/mixins/common.mixin.ts";
import axios from "axios";
import _ from "lodash";
import mailtoLink from "mailto-link";
import {
  DISTRIBUTORS_CODES,
  MIME_TYPES,
  TRIENERGO_ASSISTENT_EMAIL,
  UDAJE_O_OCHRANE_UZEMI_LIST,
} from "@/constants/form.constants";
import {STAV_CODES} from "@/constants/system.constants";
import saveAs from "save-as";

const today = new Date();
today.setHours(12, 0, 0, 0);

export default {
  components: {},
  props: {
    id: {type: String},
    kodZakazky: { type: String },
    show: { type: Boolean },
  },
  mixins: [commonMixin],
  computed: {
    isVyhra() {
      return this.info?.stav === STAV_CODES.VYHRA
    },
    getBatteryCount() {
      return _.get(this.info, 'battery.count', '');
    },
    getBatteryType() {
      return _.get(this.info, 'battery.type', '');
    },
    getPocetOptimizeru() {
      return this.info?.optimizers ? `, počet optimizérů - <b>${this.info.optimizers}</b><br>` : '<br>';
    },
    getTypBaterie() {
      return this.getBatteryCount ? `typ baterie - <b>${this.getBatteryCount}</b>x <b>${this.getBatteryType}</b><br>` : '';
    },
    getNabiecka() {
      return this.info?.charger ? `, nabíječka - <b>${this.info.charger}</b><br>` : '<br>';
    },
    getZpusobyOchranyNemovitosti() {
      const { territoryProtectionInfo } = this.info;
      if (!territoryProtectionInfo) {
        return '';
      }

      const trueKeys = Object.keys(territoryProtectionInfo)?.filter(key => territoryProtectionInfo[key]) || [];
      if (!trueKeys?.length) {
        return '';
      }

      if (trueKeys.length === 1 && trueKeys[0] === 'opt0') {
        return 'nejsou evidovány žádné způsoby ochrany'
      }

      return trueKeys.map(key => UDAJE_O_OCHRANE_UZEMI_LIST[key.toUpperCase()]).join(', ');
    },
    getOfFileName() {
      return [this.kodZakazky, this.info.clientLastName, this.info.clientName, 'OF.xlsx'].join('_');
    },
    getPowerPlantCapacity() {
      return _.get(this.info, 'smlouvaDilo.powerPlantCapacity', null);
    },
    getDistributor() {
      return _.get(this.info, 'distributor', null);
    },
    getPonoInfo() {
      const power = this.getPowerPlantCapacity;
      let distributor = this.getDistributor;
      if (!distributor && !power) return '';

      const powerStr = _.isNull(power) ? '' : `${power} kWp`;
      if (distributor === DISTRIBUTORS_CODES.LDS) {
        distributor = `${distributor} ${this.info?.ldsDistributorComment || ''}`.trim();
      }
      return `(${[distributor, powerStr].filter(Boolean).join('; ')})`;
    },
    getPonoFileName() {
      const power = this.getPowerPlantCapacity ? `${this.getPowerPlantCapacity} kWp` : null;
      return `${[this.kodZakazky, 'PONO', this.getDistributor, this.info?.ldsDistributorComment, power].filter(Boolean).join('_')}.xlsx`;
    },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      zakazkaHistory: [],
      popisPripadu: null,
      popisServisu: null,
      info: {
        stav: null, numberOfPanels: null, panelType: null, numberOfThongs: null, optimizers: null, inverterType: null,
        accessories: null, charger: null, addressInstallation: null, kraj: null, personasInfo: [{email: null, phone: null, name: null, lastName: null}],
        battery: {count: null, type: null},
      },
    }
  },
  methods: {
    async getZakazkaHistory() {
      try {
        const url = `/obchod/getZakazkaHistory`;
        const { data: {data} } = await axios.get(url, {params: {id: this.id}});
        this.zakazkaHistory = data?.history?.zakazkaHistory || [];
      } catch (e) {
        console.error('getSavedData', e.response?.data)
      }
    },
    async getPopisPripadu() {
      try {
        const url = `/obchod/getPopisPripadu`;
        const { data: {data} } = await axios.get(url, {params: {id: this.id}});
        this.popisPripadu = this.escapeLikeTagsText(data?.pripad?.comment);
      } catch (e) {
        console.error('getSavedData', e.response?.data)
      }
    },
    async getPopisServisu() {
      try {
        const url = `/obchod/getPopisServisu`;
        const { data: {data} } = await axios.get(url, {params: {id: this.id}});
        this.popisServisu = this.escapeLikeTagsText(data?.pripad?.commentService);
      } catch (e) {
        console.error('getSavedData', e.response?.data)
      }
    },
    getMailTo(email) {
      return  mailtoLink({
        to: email,
        cc: TRIENERGO_ASSISTENT_EMAIL,
      });
    },
    getFirstLine() {
      return `${this.info.orderNumber}`.trim();
    },
    getKrajNameAndOkres() {
      let krajNameAndOkres = [];
      krajNameAndOkres.push(this.info.kraj);
      krajNameAndOkres.push(this.getOkres(this.info));
      krajNameAndOkres = krajNameAndOkres.filter(r => r);
      const krajOkres = krajNameAndOkres.length ? `(${krajNameAndOkres.join(', ')})` : '';
      return `${krajOkres}`.trim();
    },
    getAddresaInstalace() {
      const {cityInstallation, addressInstallation, cislaDomu, cisloOrientacni, evidencniCisloDomu, zip} = this.info;
      const address = [];
      const joinedDomNumbers = this.getJoinedDomNumbers(cislaDomu, cisloOrientacni, evidencniCisloDomu);
      if (addressInstallation || joinedDomNumbers) {
        const addrDom = `${addressInstallation || ''} ${joinedDomNumbers || ''}`;
        address.push(`${addrDom.trim()},`);
      }
      address.push(zip || '');
      address.push(cityInstallation || '');
      address.push(this.getKrajNameAndOkres() || '')
      return address.filter(a => a).join(' ');
    },
    getJoinedName(item) {
      const joinedName = `${item.lastName || '-'} ${item.name || '-'}`;
      return joinedName.trim();
    },
    getKatastralniUzemi() {
      return  this.info.cadastralTerritory || '';
    },
    getStojiNaPozemku() {
      const standsOnProperty = this.info.standsOnProperty || '';
      const cisloLV = this.info?.numberLV ? `(číslo LV - ${this.info.numberLV})` : '';
      return `${standsOnProperty} ${cisloLV}`.trim();
    },
    async getCardBasicInfo() {
      try {
        const url = `/obchod/getCardBasicInfo`;
        const { data: {data} } = await axios.get(url, {params: {id: this.id}});
        this.info = data?.info || {...this.info};
      } catch (e) {
        this.$snotify['error']('Internal Server Error');
        console.error('getSavedData', e.response?.data)
      }
    },
    getGdprFileName(osoba, indx) {
      return [this.kodZakazky, osoba.name, osoba.lastName, 'GDPR', indx + 1, '(nepodepsáno).pdf'].join('_');
    },
    async generateXlsxOF() {
      try {
        const fileName = this.getOfFileName;

        const url = `/generate/xlsx/OF`;
        const {data} = await axios({
          method: "get",
          url: url,
          params: {id: this.id},
          responseType: "blob",
        });
        const blob = new Blob([data], { type: `${ MIME_TYPES.xlsx };base64,` });
        this.$snotify['success']('OF vygenerováno');
        saveAs(blob, fileName);
      } catch (e) {
        console.error('generateXlsxOF', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async generateGDPR(persona, index) {
      try {
        const fileName = this.getGdprFileName(persona, index);

        const url = `/obchod/generateGDPR`;
        const {data} = await axios.post(url, {id: this.id, indx: index}, {responseType: "blob"});
        if (data?.type !== MIME_TYPES.pdf) {
          return this.$snotify['error']('GDPR se nepodařilo vygenerovat');
        }

        this.$snotify['success']('GDPR vygenerováno');
        saveAs(data, fileName);
      } catch (e) {
        console.error('generateGDPR', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async generatePONO() {
      try {
        const fileName = this.getPonoFileName;
        const url = `/generate/xlsx/PONO`;
        const {data} = await axios.post(url, { contractId: this.id }, { responseType: "blob" });
        if (data?.type !== MIME_TYPES.xlsx) {
          return this.$snotify['error']('Soubor se nepodařilo vygenerovat');
        }
        this.$snotify['success']('Soubor vygenerován');
        saveAs(data, fileName);
      } catch (e) {
        console.error('generatePONO', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getZakazkaHistory();
    await this.getPopisPripadu();
    await this.getPopisServisu();
    await this.getCardBasicInfo();
    this.isLoading = false;
    this.showLocal = this.show;
  },

}
</script>

<style scoped>
.zakazkaHistory {
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
}
.m-height, .card {
  min-height: 55vh;
}
.line-info {
  padding-top: 4px;
}
.tab-content-karta {
  height: 50vh;
  overflow-y: auto;
}
</style>
