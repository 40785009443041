var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"scrollable":"","centered":"","hide-footer":"","no-close-on-backdrop":"","dialog-class":"tymZakazky-form","body-class":"p-bottom-0"},on:{"hidden":function($event){return _vm.$emit('close-modal')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h6',{staticClass:"text-nowrap w-100"},[_vm._v(" Tým zakázky č. "+_vm._s(_vm.kodZakazky))])])]},proxy:true}]),model:{value:(_vm.showLocal),callback:function ($$v) {_vm.showLocal=$$v},expression:"showLocal"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('div',{staticClass:"modalWindow-w100",on:{"click":function($event){$event.stopPropagation();}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.saveTymZakazky)}}},[_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"label-1 w-50 pr-2"},[_c('label',{staticClass:"pr-1",attrs:{"for":"obchodniZastupce"}},[_vm._v("Obchodní zástupce")]),_c('label',{staticClass:"req"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"rules":{ required: true, mustBeInList: { list: _vm.lists.obchodniZastupce }},"name":"'Obchodní zástupce'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"obchodniZastupce","list":"obchodniZastupceList","name":"obchodniZastupce","disabled":_vm.isSelectOzNameDisabled,"title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('obchodniZastupceName', null,'obchodniZastupce')},on:{"input":_vm.selectOzName,"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.obchodniZastupceName),callback:function ($$v) {_vm.$set(_vm.formData, "obchodniZastupceName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.obchodniZastupceName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"obchodniZastupceList","options":_vm.lists.obchodniZastupce,"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"label-1 w-50 pr-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"managerKoordinator"}},[_vm._v("Projektový manažer / koordinátor")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.managerKoordinator }},"name":"'Projektový manažer / koordinátor'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"managerKoordinator","list":"managerKoordinatorList","name":"managerKoordinator","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('managerKoordinatorName', null,'managerKoordinator', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.managerKoordinatorName),callback:function ($$v) {_vm.$set(_vm.formData, "managerKoordinatorName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.managerKoordinatorName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"managerKoordinatorList","options":_vm.lists.managerKoordinator,"autocomplete":"off"}})],1),_c('b-form-group',{staticClass:"w-50 pl-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"zastupcePMPK"}},[_vm._v("Zástupce PM/PK")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.zastupcePMPK }},"name":"'Zástupce PM/PK'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"zastupcePMPK","list":"zastupcePMPKrList","name":"zastupcePMPK","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('zastupcePMPKName', null,'zastupcePMPK', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.zastupcePMPKName),callback:function ($$v) {_vm.$set(_vm.formData, "zastupcePMPKName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.zastupcePMPKName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"zastupcePMPKrList","options":_vm.lists.zastupcePMPK,"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"label-1 w-50 pr-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"managerKoordinatorDalsi"}},[_vm._v("Projektový manažer / koordinátor")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.managerKoordinatorDalsi }},"name":"'Projektový manažer / koordinátor'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"managerKoordinatorDalsi","list":"managerKoordinatorDalsiList","name":"managerKoordinatorDalsi","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('managerKoordinatorDalsi', null,'managerKoordinatorDalsiName', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.managerKoordinatorDalsiName),callback:function ($$v) {_vm.$set(_vm.formData, "managerKoordinatorDalsiName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.managerKoordinatorDalsiName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"managerKoordinatorDalsiList","options":_vm.lists.managerKoordinatorDalsi,"autocomplete":"off"}})],1),_c('b-form-group',{staticClass:"w-50 pl-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"zastupcePMPKDalsi"}},[_vm._v("Zástupce PM/PK")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.zastupcePMPKDalsi }},"name":"'Zástupce PM/PK'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"zastupcePMPKDalsi","list":"zastupcePMPKDalsiList","name":"zastupcePMPKDalsi","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('zastupcePMPKDalsiName', null,'zastupcePMPKDalsi', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.zastupcePMPKDalsiName),callback:function ($$v) {_vm.$set(_vm.formData, "zastupcePMPKDalsiName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.zastupcePMPKDalsiName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"zastupcePMPKDalsiList","options":_vm.lists.zastupcePMPKDalsi,"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"label-1 w-50 pr-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"pripravaPVSol"}},[_vm._v("Příprava PV*Sol")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.pripravaPVSol }},"name":"'Příprava PV*Sol'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"pripravaPVSol","list":"pripravaPVSolList","name":"pripravaPVSol","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('pripravaPVSolName', null,'pripravaPVSol', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.pripravaPVSolName),callback:function ($$v) {_vm.$set(_vm.formData, "pripravaPVSolName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.pripravaPVSolName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"pripravaPVSolList","options":_vm.lists.pripravaPVSol,"autocomplete":"off"}})],1),_c('b-form-group',{staticClass:"w-50 pl-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"kontrolaPVSol"}},[_vm._v("Kontrola PV*Sol")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.kontrolaPVSol }},"name":"'Kontrola PV*Sol'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"kontrolaPVSol","list":"kontrolaPVSolList","name":"kontrolaPVSol","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('kontrolaPVSolName', null,'kontrolaPVSol', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.kontrolaPVSolName),callback:function ($$v) {_vm.$set(_vm.formData, "kontrolaPVSolName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.kontrolaPVSolName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"kontrolaPVSolList","options":_vm.lists.kontrolaPVSol,"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"label-1 w-50 pr-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"projektant"}},[_vm._v("Projektant")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.projektant }},"name":"'Projektant'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"projektant","list":"projektantList","name":"projektant","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('projektantName', null,'projektant', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.projektantName),callback:function ($$v) {_vm.$set(_vm.formData, "projektantName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.projektantName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"projektantList","options":_vm.lists.projektant,"autocomplete":"off"}})],1),_c('b-form-group',{staticClass:"w-50 pl-2"},[_c('label',{staticClass:"pr-1 p-t-b",attrs:{"for":"nahradniProjektant"}},[_vm._v("Náhradní projektant")]),_c('ValidationProvider',{attrs:{"rules":{ mustBeInList: { list: _vm.lists.nahradniProjektant }},"name":"'Náhradní projektant'","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control form-control-sm",attrs:{"id":"nahradniProjektant","list":"nahradniProjektantList","name":"nahradniProjektant","title":errors[0],"state":_vm.getState(classes) && !_vm.validateWithDataFromList('nahradniProjektantName', null,'nahradniProjektant', true)},on:{"change":_vm.updateInfoChangeHistory},model:{value:(_vm.formData.nahradniProjektantName),callback:function ($$v) {_vm.$set(_vm.formData, "nahradniProjektantName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.nahradniProjektantName"}})]}}],null,true)}),_c('b-datalist',{attrs:{"id":"nahradniProjektantList","options":_vm.lists.nahradniProjektant,"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"d-flex flex-column align-items-center text-center height-submit-block"},[_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"mt-4",class:[_vm.isSaveBtnEnabled ? '' : 'disabled'],attrs:{"type":"submit","variant":"primary","disabled":!_vm.isSaveBtnEnabled}},[_vm._v(" Uložit data ")])],1),_c('div',{staticClass:"w-100 pt-1px"},[(_vm.infoChangeHistory.personName)?_c('span',{staticClass:"color-grey fs-11px"},[_vm._v(" naposled změnu provedl(a): "+_vm._s(_vm.infoChangeHistory.personName)+" ")]):_vm._e()])])])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }