<template>
  <b-modal
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="tymZakazky-form"
      body-class="p-bottom-0"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100"> Tým zakázky č. {{ kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(saveTymZakazky)">
            <div class="d-flex w-100">
              <b-form-group class="label-1 w-50 pr-2">
                <label for="obchodniZastupce" class="pr-1">Obchodní zástupce</label>
                <label class="req">*</label>
                <ValidationProvider :rules="{ required: true, mustBeInList: { list: lists.obchodniZastupce }}" name="'Obchodní zástupce'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="obchodniZastupce"
                      list="obchodniZastupceList"
                      name="obchodniZastupce"
                      class="form-control form-control-sm"
                      v-model.trim="formData.obchodniZastupceName"
                      :disabled="isSelectOzNameDisabled"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('obchodniZastupceName', null,'obchodniZastupce')"
                      @input="selectOzName"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="obchodniZastupceList"
                    :options="lists.obchodniZastupce"
                    autocomplete="off"
                />
              </b-form-group>
            </div>

            <div class="d-flex w-100">
              <b-form-group class="label-1 w-50 pr-2">
                <label for="managerKoordinator" class="pr-1 p-t-b">Projektový manažer / koordinátor</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.managerKoordinator }}" name="'Projektový manažer / koordinátor'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="managerKoordinator"
                      list="managerKoordinatorList"
                      name="managerKoordinator"
                      class="form-control form-control-sm"
                      v-model.trim="formData.managerKoordinatorName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('managerKoordinatorName', null,'managerKoordinator', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="managerKoordinatorList"
                    :options="lists.managerKoordinator"
                    autocomplete="off"
                />
              </b-form-group>

              <b-form-group class="w-50 pl-2">
                <label for="zastupcePMPK" class="pr-1 p-t-b">Zástupce PM/PK</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.zastupcePMPK }}" name="'Zástupce PM/PK'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="zastupcePMPK"
                      list="zastupcePMPKrList"
                      name="zastupcePMPK"
                      class="form-control form-control-sm"
                      v-model.trim="formData.zastupcePMPKName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('zastupcePMPKName', null,'zastupcePMPK', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="zastupcePMPKrList"
                    :options="lists.zastupcePMPK"
                    autocomplete="off"
                />
              </b-form-group>
            </div>

            <div class="d-flex w-100">
              <b-form-group class="label-1 w-50 pr-2">
                <label for="managerKoordinatorDalsi" class="pr-1 p-t-b">Projektový manažer / koordinátor</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.managerKoordinatorDalsi }}" name="'Projektový manažer / koordinátor'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="managerKoordinatorDalsi"
                      list="managerKoordinatorDalsiList"
                      name="managerKoordinatorDalsi"
                      class="form-control form-control-sm"
                      v-model.trim="formData.managerKoordinatorDalsiName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('managerKoordinatorDalsi', null,'managerKoordinatorDalsiName', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="managerKoordinatorDalsiList"
                    :options="lists.managerKoordinatorDalsi"
                    autocomplete="off"
                />
              </b-form-group>

              <b-form-group class="w-50 pl-2">
                <label for="zastupcePMPKDalsi" class="pr-1 p-t-b">Zástupce PM/PK</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.zastupcePMPKDalsi }}" name="'Zástupce PM/PK'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="zastupcePMPKDalsi"
                      list="zastupcePMPKDalsiList"
                      name="zastupcePMPKDalsi"
                      class="form-control form-control-sm"
                      v-model.trim="formData.zastupcePMPKDalsiName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('zastupcePMPKDalsiName', null,'zastupcePMPKDalsi', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="zastupcePMPKDalsiList"
                    :options="lists.zastupcePMPKDalsi"
                    autocomplete="off"
                />
              </b-form-group>

            </div>

            <div class="d-flex w-100">
              <b-form-group class="label-1 w-50 pr-2">
                <label for="pripravaPVSol" class="pr-1 p-t-b">Příprava PV*Sol</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.pripravaPVSol }}" name="'Příprava PV*Sol'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="pripravaPVSol"
                      list="pripravaPVSolList"
                      name="pripravaPVSol"
                      class="form-control form-control-sm"
                      v-model.trim="formData.pripravaPVSolName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('pripravaPVSolName', null,'pripravaPVSol', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="pripravaPVSolList"
                    :options="lists.pripravaPVSol"
                    autocomplete="off"
                />
              </b-form-group>

              <b-form-group class="w-50 pl-2">
                <label for="kontrolaPVSol" class="pr-1 p-t-b">Kontrola PV*Sol</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.kontrolaPVSol }}" name="'Kontrola PV*Sol'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="kontrolaPVSol"
                      list="kontrolaPVSolList"
                      name="kontrolaPVSol"
                      class="form-control form-control-sm"
                      v-model.trim="formData.kontrolaPVSolName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('kontrolaPVSolName', null,'kontrolaPVSol', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="kontrolaPVSolList"
                    :options="lists.kontrolaPVSol"
                    autocomplete="off"
                />
              </b-form-group>

            </div>

            <div class="d-flex w-100">
              <b-form-group class="label-1 w-50 pr-2">
                <label for="projektant" class="pr-1 p-t-b">Projektant</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.projektant }}" name="'Projektant'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="projektant"
                      list="projektantList"
                      name="projektant"
                      class="form-control form-control-sm"
                      v-model.trim="formData.projektantName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('projektantName', null,'projektant', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="projektantList"
                    :options="lists.projektant"
                    autocomplete="off"
                />
              </b-form-group>

              <b-form-group class="w-50 pl-2">
                <label for="nahradniProjektant" class="pr-1 p-t-b">Náhradní projektant</label>
                <ValidationProvider :rules="{ mustBeInList: { list: lists.nahradniProjektant }}" name="'Náhradní projektant'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                      id="nahradniProjektant"
                      list="nahradniProjektantList"
                      name="nahradniProjektant"
                      class="form-control form-control-sm"
                      v-model.trim="formData.nahradniProjektantName"
                      :title="errors[0]"
                      :state="getState(classes) && !validateWithDataFromList('nahradniProjektantName', null,'nahradniProjektant', true)"
                      @change="updateInfoChangeHistory"
                  />
                </ValidationProvider>
                <b-datalist
                    id="nahradniProjektantList"
                    :options="lists.nahradniProjektant"
                    autocomplete="off"
                />
              </b-form-group>

            </div>

            <div class="d-flex flex-column align-items-center text-center height-submit-block">
              <div class="w-100">
                <b-button
                    type="submit"
                    class="mt-4"
                    :class="[isSaveBtnEnabled ? '' : 'disabled']"
                    variant="primary"
                    :disabled="!isSaveBtnEnabled"
                >
                  Uložit data
                </b-button>
              </div>

              <div class="w-100 pt-1px">
                <span class="color-grey fs-11px" v-if="infoChangeHistory.personName"> naposled změnu provedl(a): {{ infoChangeHistory.personName }} </span>
              </div>
            </div>
          </b-form>
          </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import _ from "lodash"
import {mapGetters} from "vuex";
import commonMixin from "@/mixins/common.mixin.ts";
import moment from "moment";
import Axios from "axios";

export default {
  name: "TymZakazky",
  computed: {
    isNoNumberOzOrCanSeeAllZakazky() {
      return !this.$store.getters.getUserNumberOZ || this.$store.getters.isCanSeeAllZakazky;
    },
    isSelectOzNameDisabled() {
      return this.id && !this.isNoNumberOzOrCanSeeAllZakazky;
    },
    ...mapGetters(['getUserNamesAndTitles', 'getUserNumberOZ', 'isCanSeeAllZakazky']),
  },
  mixins: [commonMixin],
  props: {
    id: { type: String },
    kodZakazky: { type: String },
    show: { type: Boolean },
  },
  data() {
    return {
      showLocal: true,
      isLoading: false,
      isSaveBtnEnabled: true,
      infoChangeHistory: {
        personName: null
      },
      obchodniZastupce: [],
      obchodniZastupceFull: [],
      obchodniZastupceObjById: {},
      managerKoordinator: [],
      managerKoordinatorFull: [],
      managerKoordinatorObjById: {},
      managerKoordinatorDalsi: [],
      zastupcePMPK: [],
      zastupcePMPKDalsi: [],
      pripravaPVSol: [],
      pripravaPVSolFull: [],
      pripravaPVSolObjById: {},
      kontrolaPVSol: [],
      projektant: [],
      projektantFull: [],
      nahradniProjektant: [],
      nahradniProjektantObjById: {},
      formData: {
        obchodniZastupceId: null,
        obchodniZastupceName: '',
        managerKoordinatorId: null,
        managerKoordinatorName: '',
        managerKoordinatorDalsiId: null,
        managerKoordinatorDalsiName: '',
        zastupcePMPKId: null,
        zastupcePMPKName: '',
        zastupcePMPKDalsiId: null,
        zastupcePMPKDalsiName: '',
        pripravaPVSolId: null,
        pripravaPVSolName: '',
        kontrolaPVSolId: null,
        kontrolaPVSolName: '',
        projektantId: null,
        projektantName: '',
        nahradniProjektantId: null,
        nahradniProjektantName: '',
      },
      lists: {
        obchodniZastupce: [],
        managerKoordinator: [],
        managerKoordinatorDalsi: [],
        zastupcePMPK: [],
        zastupcePMPKDalsi: [],
        pripravaPVSol: [],
        kontrolaPVSol: [],
        projektant: [],
        nahradniProjektant: [],
      },
    }
  },
  methods: {
    async getObchodniZastupceList() {
      const url = `/user/getOzUsersGroupsForUser`;
      const {data} = await Axios.get(url, {});
      this.obchodniZastupce = data?.data?.activeOzUsersNamesFioTitles || [];
      this.obchodniZastupceFull = data?.data?.activeOzUsersInfo || [];
      this.obchodniZastupceFull.forEach(user => {
        this.obchodniZastupceObjById[user.id] = user.fullNameFioTitles;
      })
    },

    async getManagersKoordinators() {
      const url = `/user/getManagersKoordinators`;
      const {data} = await Axios.get(url, {});
      this.managerKoordinator = data?.data?.managersKoordinatorsNamesFioTitles || [];
      this.managerKoordinatorFull = data?.data?.managersKoordinatorsInfo || [];
      this.managerKoordinatorFull.forEach(user => {
        this.managerKoordinatorObjById[user.id] = user.fullNameFioTitles;
      })
    },

    async getPVSols() {
      const url = `/user/getPVSols`;
      const {data} = await Axios.get(url, {});
      this.pripravaPVSol = data?.data?.pvSolsNamesFioTitles || [];
      this.pripravaPVSolFull = data?.data?.pvSolsInfo || [];
      this.pripravaPVSolFull.forEach(user => {
        this.pripravaPVSolObjById[user.id] = user.fullNameFioTitles;
      })
    },

    async getProjektants() {
      const url = `/user/getProjektants`;
      const {data} = await Axios.get(url, {});
      this.projektant = data?.data?.projektantsNamesFioTitles || [];
      this.projektantFull = data?.data?.projektantsInfo || [];
      this.projektantFull.forEach(user => {
        this.nahradniProjektantObjById[user.id] = user.fullNameFioTitles;
      })
    },

    async getSavedData() {
      this.lists.obchodniZastupce = this.obchodniZastupce;
      this.lists.managerKoordinator = this.managerKoordinator;
      this.lists.managerKoordinatorDalsi = this.managerKoordinator;
      this.lists.zastupcePMPK = this.managerKoordinator;
      this.lists.zastupcePMPKDalsi = this.managerKoordinator;
      this.lists.projektant = this.projektant;
      this.lists.nahradniProjektant = this.projektant;
      this.lists.pripravaPVSol = this.pripravaPVSol;
      this.lists.kontrolaPVSol = this.pripravaPVSol;

      const url = `/contract/getTymZakazkyFormData`;
      const { data: {data} } = await Axios.post(url, { id: this.id });
      const info = data || {};
      this.formData = {...this.formData, ...info};
      this.infoChangeHistory = {...this.infoChangeHistory, ..._.get(data, 'infoChangeHistory', {})};
      this.formData.obchodniZastupceName = _.get(this, `obchodniZastupceObjById.${info.obchodniZastupceId}`, '');
      this.formData.managerKoordinatorName = _.get(this, `managerKoordinatorObjById.${info.managerKoordinatorId}`, '');
      this.formData.managerKoordinatorDalsiName = _.get(this, `managerKoordinatorObjById.${info.managerKoordinatorDalsiId}`, '');
      this.formData.zastupcePMPKName = _.get(this, `managerKoordinatorObjById.${info.zastupcePMPKId}`, '');
      this.formData.zastupcePMPKDalsiName = _.get(this, `managerKoordinatorObjById.${info.zastupcePMPKDalsiId}`, '');
      this.formData.pripravaPVSolName = _.get(this, `pripravaPVSolObjById.${info.pripravaPVSolId}`, '');
      this.formData.kontrolaPVSolName = _.get(this, `pripravaPVSolObjById.${info.kontrolaPVSolId}`, '');
      this.formData.projektantName = _.get(this, `nahradniProjektantObjById.${info.projektantId}`, '');
      this.formData.nahradniProjektantName = _.get(this, `nahradniProjektantObjById.${info.nahradniProjektantId}`, '');
    },

    async saveTymZakazky() {
      try {
        this.isLoading = true;
        const url = `/contract/saveTymZakazky`;
        this.formData.obchodniZastupceId = this.getUserIdByName(this.formData.obchodniZastupceName, this.obchodniZastupceFull);
        this.formData.managerKoordinatorId = this.getUserIdByName(this.formData.managerKoordinatorName, this.managerKoordinatorFull);
        this.formData.managerKoordinatorDalsiId = this.getUserIdByName(this.formData.managerKoordinatorDalsiName, this.managerKoordinatorFull);
        this.formData.zastupcePMPKId = this.getUserIdByName(this.formData.zastupcePMPKName, this.managerKoordinatorFull);
        this.formData.zastupcePMPKDalsiId = this.getUserIdByName(this.formData.zastupcePMPKDalsiName, this.managerKoordinatorFull);
        this.formData.pripravaPVSolId = this.getUserIdByName(this.formData.pripravaPVSolName, this.pripravaPVSolFull);
        this.formData.kontrolaPVSolId = this.getUserIdByName(this.formData.kontrolaPVSolName, this.pripravaPVSolFull);
        this.formData.projektantId = this.getUserIdByName(this.formData.projektantName, this.projektantFull);
        this.formData.nahradniProjektantId = this.getUserIdByName(this.formData.nahradniProjektantName, this.projektantFull);
        delete this.formData['infoChangeHistory'];
        const changerNameAndDate = this.infoChangeHistory
        const {data} = await Axios.post(url, { data: this.formData, changerNameAndDate, id: this.id});
        if (data.status === 'success') {
          this.$snotify[data.status](data.data.message);
          this.$emit('save-modal')
          this.$emit('close-modal');
        }
        this.isLoading = false;
      } catch (e) {
        console.error('saveTymZakazky', e.response?.data);
      }
    },

    getUserIdByName(userName, userObject) {
      const userByName = userObject.find(user => userName === user.fullNameFioTitles);
      return userByName?.id || null;
    },

    validateWithDataFromList(key, isInput, listName, isCanBeEmpty = false) {
      if (isCanBeEmpty && !this.formData[key]) return false;
      const isEqual = this.lists[listName || key].includes(_.get(this.formData, key));
      return isInput && !isEqual ? false : !isEqual;
    },

    async selectOzName() {
      if (this.id) return;

      if (!this.formData.obchodniZastupceName) {
        this.formData.kodZakazky = moment().format('YY');
        return;
      }

      const userOzByName = this.obchodniZastupceFull.find(user => user.fullNameFioTitles === this.formData.obchodniZastupceName);
      let numberOZ = userOzByName?.numberOZ || '';
      if (numberOZ) {
        this.formData.kodZakazky = await this.getNextFreeZakazkaNumber(numberOZ);
        await this.changeObject(false);
        await this.changeCategory();
      }
      await this.validateKodZakazkyOnExist();
    },

    updateInfoChangeHistory() {
      this.infoChangeHistory.personName = this.getChangerInfoString();
    },

    getChangerInfoString(editorInfo = null) {
      const editor = editorInfo || this.getEditorNameAndDateEdit();
      return `${editor.name}, ${editor.date}`
    },

    getEditorNameAndDateEdit() {
      return {
        date: this.getDateOfChangesInfo(),
        name: this.$store.getters.getUserNamesAndTitles,
      };
    },

    getDateOfChangesInfo() {
      return moment().format('DD.MM.YYYY');
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.getObchodniZastupceList(),
      this.getManagersKoordinators(),
      this.getPVSols(),
      this.getProjektants(),
    ]);
    await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },
}
</script>

<style scoped>
.req {
  color: darkred;
  font-size: 17px
}
.p-t-b {
  padding-top: 2px;
  padding-bottom: 2px;
}
.height-submit-block {
  height: 90px;
}
</style>