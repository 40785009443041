<template>
  <div>
    <b-btn v-for="(tab, index) in tabs"
           :key="tab.tabName"
           :to="tab.link"
           :variant="getButtonVariant(tab.tabName)"
           :class="[index ? 'ml-4' : '']"
           :disabled="tab.disabled || false"
           @click="clickOnButton"
           :title="tab.title"
    >
      {{ capitalize(tab.name) }}
    </b-btn>
  </div>
</template>
<script>

export default {
  name: 'tab-buttons',
  emits: ['click'],
  props: {
    tabName: {type: String, required: true},
    tabs: {type: Array, default: () => []},
  },
  methods: {
    getButtonVariant(tabName) {
      if (tabName === this.tabName) return 'primary';
      return 'outline-primary';
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    clickOnButton() {
      this.$emit('click');
    }
  },
}
</script>